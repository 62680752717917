import { PageProps } from 'gatsby';
import { InquiryData } from 'packages/innedit';
import React, { FC } from 'react';

import CMSView from '~/components/View';
import Form from '~/containers/Espace/Form';
import contact from '~/params/inquiry.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const DemandeUpdate: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, demandeId },
}) => {
  const model = new InquiryData({
    espaceId,
    params: contact,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          displayArchive
          displayRemove={false}
          docId={demandeId}
          model={model}
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(DemandeUpdate);
